import React, { useEffect, useState } from "react";
import {
  useLoginMutation,
  useCurrentUserQuery,
} from "../features/user/userApiSlice";
import { HeadProps, Link, navigate } from "gatsby";
import toast from "react-hot-toast";
import { useAppSelector } from "../app/hooks";
import { handleErrorResponse } from "../app/apiSlice";
import FormPageWrapper from "../components/FormPageWrapper";
import Layout from "../components/Layout";
import ButtonPrimary from "../components/ButtonPrimary";
import ButtonSpinner from "../components/ButtonSpinner";
import { SubmitHandler, useForm } from "react-hook-form";
import FormErrorMessage from "../components/FormErrorMessage";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import Seo from "../features/seo/Seo";
import * as Sentry from "@sentry/gatsby";

interface LoginFormValues {
  email: string;
  pass: string;
}

const Login = () => {
  // Check if an email was entered during the checkout flow.
  const [serverError, setServerError] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<LoginFormValues>();
  const email = useAppSelector((state) => {
    return state.checkout.email;
  });
  useEffect(() => {
    if (typeof email !== "undefined") {
      setValue("email", email);
    }
  }, [email]);

  const [loginTrigger, { isLoading }] = useLoginMutation();
  const { data: currentUser, isFetching } = useCurrentUserQuery();
  const [isPasswordShowing, setIsPasswordShowing] = useState(false);

  // Redirect authenticated users to select a textbook edition.
  useEffect(() => {
    if (currentUser?.is_authenticated && !isFetching) {
      navigate("/#textbooks");
    }
  }, [currentUser?.is_authenticated, isFetching]);

  const onSubmit: SubmitHandler<LoginFormValues> = (values) => {
    setServerError("");
    loginTrigger({
      name: values.email,
      pass: values.pass,
    })
      .unwrap()
      .then((success) => {
        if (typeof gtag === "function") {
          gtag("event", "login", { method: "email" });
        }
        toast.success("Sign in successful!");
        navigate("/#textbooks");
      })
      .catch((rejected) => {
        console.error(rejected);
        let message = "";
        if (rejected?.status === 400) {
          message = "Sorry, unrecognized email, username or password";
          Sentry.captureMessage(message, "warning");
          toast.error(message);
        } else {
          const messages = handleErrorResponse(rejected);
          messages.forEach((m) => {
            const e = Error(m);
            e.name = "login: onSubmit";
            Sentry.captureException(e);
            if (typeof gtag === "function") {
              gtag("event", "exception", { description: m, fatal: false });
            }
          });
          message = messages.pop();
        }
        setServerError(message);
      });
  };

  return (
    <Layout>
      <FormPageWrapper title="Sign in">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-5">
            <label
              className="block mb-2 font-bold text-neutral-600"
              htmlFor="email"
            >
              Email or Username
            </label>
            <input
              className={`border shadow p-3 w-full rounded ${
                errors.email ? "border-red-300" : "border-neutral-300"
              }`}
              id="email"
              placeholder="email@example.com"
              autoFocus
              {...register("email", { required: true, maxLength: 254 })}
            />
            {errors.email?.type === "required" && (
              <FormErrorMessage>
                The email or username is required.
              </FormErrorMessage>
            )}
            {errors.email?.type === "maxLength" && (
              <FormErrorMessage>
                Can not exceed 254 characters.
              </FormErrorMessage>
            )}
          </div>

          <div className="mb-10">
            <label
              className="block mb-2 font-bold text-neutral-600"
              htmlFor="pass"
            >
              Password
            </label>
            <div className="relative">
              <input
                className={`border shadow p-3 w-full rounded ${
                  errors.pass ? "border-red-300" : "border-neutral-300"
                }`}
                id="pass"
                type={isPasswordShowing ? "text" : "password"}
                placeholder="xxxxxxxx"
                {...register("pass", {
                  required: true,
                })}
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    setIsPasswordShowing(!isPasswordShowing);
                  }}
                  type="button"
                >
                  {isPasswordShowing ? (
                    <BsEyeFill className="text-neutral-500 h-5 w-5" />
                  ) : (
                    <BsEyeSlashFill
                      aria-label="Your password is currently hidden. Select this to show your password."
                      className="text-neutral-500 h-5 w-5"
                    />
                  )}
                </button>
              </div>
            </div>
            {errors.pass?.type === "required" && (
              <FormErrorMessage>The password is required.</FormErrorMessage>
            )}
            {serverError && <FormErrorMessage>{serverError}</FormErrorMessage>}
            <Link
              className="inline-block mt-4 text-sm underline text-blue-700 hover:text-blue-600 hover:no-underline"
              to="/request-password-reset"
            >
              Forgot password?
            </Link>
          </div>
          <ButtonPrimary size="20" disabled={isLoading}>
            Sign in
            <ButtonSpinner enabled={isLoading} />
          </ButtonPrimary>
        </form>
        <p className="mt-8 font-bold text-neutral-700">
          Don't have an account?{" "}
          <Link className="text-blue-700 hover:text-blue-600" to="/sign-up">
            Sign up
          </Link>
        </p>
      </FormPageWrapper>
    </Layout>
  );
};

export default Login;

export const Head = (props: HeadProps) => {
  return (
    <Seo title="Log in | Giancoli Answers" pathname={props.location.pathname} />
  );
};
